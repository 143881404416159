import { template as template_5a6a5fe7d1304d2e993d2f0cbfafbeb0 } from "@ember/template-compiler";
const SidebarSectionMessage = template_5a6a5fe7d1304d2e993d2f0cbfafbeb0(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
