import { template as template_29bc669b9bd74d8eb94301809c3e2fe8 } from "@ember/template-compiler";
const FKText = template_29bc669b9bd74d8eb94301809c3e2fe8(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
