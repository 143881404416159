import { template as template_a3ca7e3c97c242468a651a15b58a73a0 } from "@ember/template-compiler";
const FKControlMenuContainer = template_a3ca7e3c97c242468a651a15b58a73a0(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
